import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmailTemplateDto } from '@core/services/dto';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';
import { LinkModel, SearchEmailTemplatesOptionsModel } from '@shared/models';
import { SearchSMSTemplatesOptionsModel } from '@shared/models/search.sms-templates.options.model';
import { DATE_WITH_TIME_FORMAT, formatDateToLocalSimple } from '@shared/utils';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnChanges,OnInit {
  @Input() templates: EmailTemplateDto[] | SearchEmailTemplatesOptionsModel[];
  @Input() templateSms: SMSTemplateDto[] | SearchSMSTemplatesOptionsModel[];
  @Input() mode: string;
  isSendDisabled: boolean ;
  @Input() memberError = false;
  @Input() TemplateType: string;
  @Input() selectedLinks: any;
  memberlinks: any;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendEmail: EventEmitter<EmailTemplateDto | SMSTemplateDto> = new EventEmitter<EmailTemplateDto | SMSTemplateDto>();
  ngOnInit(): void {
    console.log(this.selectedLinks);
    this.memberlinks = this.selectedLinks
  .filter(item => item.type === 'Member');
 this.memberlinks[0].checked = true;
    this.updateSendButtonState();
    console.log(this.memberlinks);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates && changes.templates.currentValue) {
      if (this.templates && this.templates.length && this.mode === 'view') {
        if (this.TemplateType === 'Email') {
          (this.templates as EmailTemplateDto[]).forEach((item: EmailTemplateDto) => {
            item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
          });
        } else if (this.TemplateType === 'SMS') {
          (this.templateSms as SMSTemplateDto[]).forEach((item: SMSTemplateDto) => {
            item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
          });
        }
      }
    }
  }

  send(event: EmailTemplateDto | SMSTemplateDto): void {
  const checkedMembers = this.memberlinks.filter(item => item.checked);

  // Add checkedMembers to the event object
  const updatedEvent = {
      ...event,
      checkedMembers
  };

  this.sendEmail.emit(updatedEvent);
}
Explanation;
  remove(event: EmailTemplateDto | SMSTemplateDto): void {
    this.delete.emit(event);
  }
  onSelectionChange(): void {
    this.updateSendButtonState();
  }
  onCheckboxChange(item: any) {
    item.checked = !item.checked;
    this.updateSendButtonState();
  }
  updateSendButtonState() {
    this.isSendDisabled = this.selectedLinks.every(link => !link.checked);
  }
}
