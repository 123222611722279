import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';
import { EmailTemplateDto, SearchEmailTemplatesDto } from '@core/services/dto';
import { EmailUrls, SMSUrls } from '@core/constants';
import { SendEmailRequestDto } from '@shared/models/requests/send.email.request.dto';
import { AttachmentModel } from '@shared/models';
import { EmailTemplateSearchRequestDto } from '@shared/models/requests/email-template-search.request.dto';
import { SendSMSRequestModel } from '@shared/models/send.sms.request.model';

@Injectable({
  providedIn: 'root'
})

export class EmailTemplatesApiService {

  constructor(private api: ApiClient) {
  }

  searchTemplates(request?: EmailTemplateSearchRequestDto): Observable<SearchEmailTemplatesDto> {
    return this.api.get<SearchEmailTemplatesDto>(EmailUrls.templates, request);
  }

  sendBulk(request: SendEmailRequestDto[]): Observable<SendEmailRequestDto> {
    return this.api.post<SendEmailRequestDto>(EmailUrls.sendBulk, request);
  }

  sendBulkSms(request: SendSMSRequestModel[]): Observable<SendSMSRequestModel> {
    return this.api.post<SendSMSRequestModel>(SMSUrls.sendBulkSms, request);
  }

  send(request: SendEmailRequestDto): Observable<SendEmailRequestDto> {
    return this.api.post<SendEmailRequestDto>(EmailUrls.send, request);
  }

  getTemplate(id: string): Observable<EmailTemplateDto> {
    return this.api.get<EmailTemplateDto>(EmailUrls.templateById(id));
  }

  updateTemplate(id: string, request: EmailTemplateDto): Observable<EmailTemplateDto> {
    return this.api.put<EmailTemplateDto>(EmailUrls.templateById(id), request);
  }

  createTemplate(request: EmailTemplateDto): Observable<EmailTemplateDto> {
    return this.api.post<EmailTemplateDto>(EmailUrls.templates, request);
  }

  getLayout(): Observable<AttachmentModel> {
    return this.api.get<AttachmentModel>(EmailUrls.defaultLayout);
  }

  getLayoutById(id: number): Observable<AttachmentModel> {
    return this.api.get<AttachmentModel>(EmailUrls.layoutById(id));
  }
}
