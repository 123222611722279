import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmailUrls, SMSUrls, TaskUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { TaskUpdateApiRequest } from '@shared/models/requests/task-update.request.dto';
import { Task, TaskCreateInterface } from '@shared/models';
import { TasksApiRequest } from '@shared/models/requests/tasks.request.dto';
import { EmailTemplateDto, PaginatedResponseDto, TasksDto, TasksSummaryDto } from '@core/services/dto';
import { TaskTemplateDto } from '@core/services/dto/task-template.dto';
import { TaskTemplateRequest } from '@shared/models/requests/task-template.request.dto';
import { TaskTemplate } from '@shared/models/task-template';
import { SMSTemplateDto } from '../dto/sms-template.dto';
import { ChangeLogsDto } from '../dto/change-logs.dto';


@Injectable({
  providedIn: 'root'
})
export class TaskApiService {
  private taskUpdateSubject = new BehaviorSubject<void>(null);
  public notiyConversation = new BehaviorSubject<void>(null);
  constructor(private api: ApiClient) {
  }

  notifyTaskUpdate(): void {
    this.taskUpdateSubject.next();
  }

  getTaskUpdateNotifier(): Observable<void> {
    return this.taskUpdateSubject.asObservable();
  }

  notifyConversationUpdate(): void {
    this.notiyConversation.next();
  }

  getConversationUpdateNotifier(): Observable<void> {
    return this.notiyConversation.asObservable();
  }

  createTask(task: TaskCreateInterface): Observable<Task> {
    return this.api.post<Task>(TaskUrls.tasks, task);
  }

  updateTask(taskId: number, request: TaskUpdateApiRequest): Observable<Task> {
    return this.api.put(TaskUrls.task(taskId), request);
  }

  getTasks(request: TasksApiRequest): Observable<PaginatedResponseDto<TasksDto>> {
    return this.api.get<PaginatedResponseDto<TasksDto>>(TaskUrls.tasks, request);
  }

  getTask(id: number): Observable<TasksDto> {
    return this.api.get<TasksDto>(TaskUrls.task(id));
  }

  getTasksSummary(statuses: string[]): Observable<TasksSummaryDto> {
    return this.api.get<TasksSummaryDto>(TaskUrls.summary(statuses));
  }

  getTaskTemplates(request: TaskTemplateRequest): Observable<PaginatedResponseDto<TaskTemplateDto>> {
    return this.api.get<PaginatedResponseDto<TaskTemplateDto>>(TaskUrls.taskTemplates, request);
  }

  createTaskTemplate(taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.api.post<TaskTemplate>(TaskUrls.taskTemplates, taskTemplate);
  }

  getTaskTemplate(id: number): Observable<TaskTemplateDto> {
    return this.api.get<TaskTemplateDto>(TaskUrls.taskTemplate(id));
  }

  updateTaskTemplate(id: number, taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.api.put(TaskUrls.taskTemplate(id), taskTemplate);
  }

  getEmailTemplates(ids: string[]): Observable<PaginatedResponseDto<EmailTemplateDto>> {
    return this.api.get(EmailUrls.templates, { includeIds: ids ,TemplateType : 'Email'});
  }

  getSMSTemplates(ids: string[]): Observable<PaginatedResponseDto<SMSTemplateDto>> {
    return this.api.get(SMSUrls.templates, { includeIds: ids, TemplateType: 'SMS' });
  }


  updateSendDate(id: string, emailId: string, date: Date): Observable<any> {
    return this.api.put(TaskUrls.updateSendDate(id, emailId), { sendDate: date });
  }

  getChangeLogs(request: TaskTemplateRequest): Observable<PaginatedResponseDto<ChangeLogsDto>> {
    return this.api.get<PaginatedResponseDto<ChangeLogsDto>>(TaskUrls.tasksHistory, request);
  }
}
